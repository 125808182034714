<template>
  <div class="contact block block--white block--rounded">
    <div class="block__row">
      <div class="block__col">
        <div class="block__title">
          Сайт
        </div>
        <div class="block__field">
          <esmp-input
            v-model="site"
            label="Введите сайт"
          />
        </div>
      </div>
      <div class="block__col">
        <div class="block__title">
          Телефон
        </div>
        <div class="block__field">
          <esmp-input
            v-model="phone"
            label="Введите телефон"
          />
        </div>
      </div>
    </div>
    <div class="block__row">
      <div class="block__col">
        <div class="block__title">
          Email
        </div>
        <div class="block__field">
          <esmp-input
            v-model="email"
            label="Введите email"
          />
        </div>
      </div>
    </div>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('contacts')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';

export default {
  name: 'Contact',
  computed: {
    ...mapState('portalStyle', ['settings']),
    site: {
      get() {
        return this.settings.common.contacts.site;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.contacts', 'site'],
          value: newValue,
        });
      },
    },
    email: {
      get() {
        return this.settings.common.contacts.email;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.contacts', 'email'],
          value: newValue,
        });
      },
    },
    phone: {
      get() {
        return this.settings.common.contacts.phone;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.contacts', 'phone'],
          value: newValue,
        });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'common', settingList: ['contacts'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки контактов успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
};
</script>

<style lang="scss">
.contact {
  .block__row {
    margin-bottom: 16px;
  }
}
</style>
