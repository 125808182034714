<template>
  <div class="meta-data block block--white block--rounded">
    <div class="block__row">
      <div class="block__col">
        <div class="block__title">
          Favicon портала
        </div>
        <div class="block__field">
          <esmp-upload
            action="/api/files"
            type="drag"
            auto-upload
            :on-success="onUploadFaviconFile"
            :show-upload-list="false"
          >
            <span class="esmp-upload__label esmp-upload-drag">Выбрать файл</span>
          </esmp-upload>
        </div>
        <div class="block__field">
          <img
            :src="faviconFile"
            alt="favicon"
          >
        </div>
      </div>
      <div class="block__col">
        <div class="block__title">
          Title портала
        </div>
        <div class="block__field">
          <esmp-input
            v-model="portalTitle"
            label="Введите название"
          />
        </div>
      </div>
    </div>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('meta-data')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';

export default {
  name: 'MetaData',
  computed: {
    ...mapState('portalStyle', ['settings']),
    portalTitle: {
      get() {
        return this.settings.common.titlePortal;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.titlePortal'],
          value: newValue,
        });
      },
    },
    faviconFile: {
      get() {
        return this.settings.common.faviconImageUrl;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.faviconImageUrl'],
          value: newValue,
        });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'common', settingList: ['titlePortal', 'faviconImageUrl'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки meta-данных успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    async onUploadFaviconFile(res) {
      this.faviconFile = await res?.url;
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
};
</script>
