<template>
  <div class="faq-style block block--white block--rounded">
    <div class="block__row">
      <div class="block__col">
        <div class="block__title">
          Изображение для категории
        </div>
        <div class="block__field">
          <esmp-upload
            action="/api/files"
            type="drag"
            auto-upload
            :on-success="onUploadFaqCategoryImageUrl"
            :show-upload-list="false"
          >
            <span class="esmp-upload__label esmp-upload-drag">Выбрать файл</span>
          </esmp-upload>
        </div>
        <div class="block__field">
          <esmp-tag
            v-if="faqCategoryImageUrl"
            color="purple"
            shape="circular"
          >
            <img
              :src="faqCategoryImageUrl"
              alt="Изображение для категории"
              class="faq-style__icon"
            >
          </esmp-tag>
        </div>
      </div>
      <div class="block__col">
        <div class="block__title">
          Изображение для статьи
        </div>
        <div class="block__field">
          <esmp-upload
            action="/api/files"
            type="drag"
            auto-upload
            :on-success="onUploadFaqArticleImageUrl"
            :show-upload-list="false"
          >
            <span class="esmp-upload__label esmp-upload-drag">Выбрать файл</span>
          </esmp-upload>
        </div>
        <div class="block__field">
          <img
            class="faq-style__image"
            v-if="faqArticleImageUrl"
            :src="faqArticleImageUrl"
            alt="Изображение для статьи"
          >
        </div>
      </div>
    </div>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('faq')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';

export default {
  name: 'Faq',
  computed: {
    ...mapState('portalStyle', ['settings']),
    faqCategoryImageUrl: {
      get() {
        return this.settings.common.faqCategoryImageUrl;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.faqCategoryImageUrl'],
          value: newValue,
        });
      },
    },
    faqArticleImageUrl: {
      get() {
        return this.settings.common.faqArticleImageUrl;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.faqArticleImageUrl'],
          value: newValue,
        });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'common', settingList: ['faqCategoryImageUrl', 'faqArticleImageUrl'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки базы знаний успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    async onUploadFaqCategoryImageUrl(res) {
      this.faqCategoryImageUrl = await res?.url;
    },
    async onUploadFaqArticleImageUrl(res) {
      this.faqArticleImageUrl = await res?.url;
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
};
</script>

<style lang="scss">
.faq-style {
  &__icon {
    width: 24px;
    display: block;
  }
  &__image {
    max-width: 260px;
  }
}
</style>
