<template>
  <div class="page page--offset page__portal-style portal-style page--bottom-indent">
    <template v-if="settings">
      <esmp-tabs value="metaData">
        <esmp-tab-pane
          label="Meta-данные"
          name="metaData"
          icon="template"
        >
          <meta-data />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="Логотип"
          name="logo"
          icon="image"
        >
          <logo-style />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="Ссылки ESMP"
          name="esmp-links"
          icon="dots"
        >
          <esmp-links />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="Контакты"
          name="contact"
          icon="control__contact"
        >
          <contact />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="Цветовая гамма портала"
          name="colors"
          icon="star"
        >
          <colors />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="Меню под поиском"
          name="bannerLinks"
          icon="default-form"
        >
          <banner-links />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="Левое меню"
          name="sidebarLinks"
          icon="menu"
        >
          <sidebar-links />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="База знаний"
          name="faq"
          icon="menu"
        >
          <faq />
        </esmp-tab-pane>
      </esmp-tabs>

      <div
        :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
      >
        <esmp-button
          class="page__action-button"
          @click="save"
        >
          Сохранить все изменения
        </esmp-button>
      </div>
    </template>
    <esmp-modal
      v-model="confirmModalShowed"
      @on-ok="setDefault(section)"
    >
      <template #header>
        Возврат к базовым настройкам
      </template>
      Вы уверены?
    </esmp-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';
import MetaData from '@/pages/admin/portal-style/MetaData.vue';
import LogoStyle from '@/pages/admin/portal-style/LogoStyle.vue';
import EsmpLinks from '@/pages/admin/portal-style/EsmpLinks.vue';
import Contact from '@/pages/admin/portal-style/Contact.vue';
import Colors from '@/pages/admin/portal-style/Colors.vue';
import BannerLinks from '@/pages/admin/portal-style/BannerLinks.vue';
import SidebarLinks from '@/pages/admin/portal-style/SidebarLinks.vue';
import Faq from '@/pages/admin/portal-style/Faq.vue';

export default {
  name: 'PortalStyle',
  components: {
    MetaData,
    LogoStyle,
    EsmpLinks,
    Contact,
    Colors,
    BannerLinks,
    SidebarLinks,
    Faq,
  },
  data() {
    return {
      confirmModalShowed: false,
      section: null,
    };
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('portalStyle', ['settings']),
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['getSettings', 'saveAllSettings', 'setDefault']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveAllSettings()
        .then(() => {
          this.$EsmpNotify.$show('Настройки успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
  },
  created() {
    this.setLoading({ key: 'page', value: true });
    this.getSettings().finally(() => {
      this.setLoading({ key: 'page', value: false });
    });
    Hub.$on('show-confirm-modal', (section) => {
      this.section = section;
      this.confirmModalShowed = true;
    });
  },
  beforeDestroy() {
    Hub.$off('show-confirm-modal');
  },
};
</script>

<style lang="scss">
.portal-style {
  .esmp-tabs-tab-active {
    .esmp-svg-icon {
      fill: $color-white;
    }
  }
  .block__title {
    color: $color-black-op-70;
  }
  .esmp-upload {
    width: 100%;

    .esmp-upload__label {
      display: inline-block;
      width: 100%;
      padding: 12px;
    }
  }
}
</style>
